<template>
    <div class="box" style="width:100%;height:100%">
        <div class="el-icon-loading" style="font-size:250px;color:#409EFF;font-weight:100;position:relative">
        </div>
        <div class="text">正在跳转请稍等 . . .</div>
    </div>
</template>

<script>
import request from '@/utils/request.js'
export default {
    data(){
        return {
            num:0,
            timer:''
        };
    },
    created(){
        this.jump()
    },
    methods:{
        jump(){
            this.timer = setInterval(()=>{
                this.num++
                console.log(this.num);
                if(this.num==3){
                    this.$router.replace('/home')
                } 
            },1000)
        }
    },
    beforeDestroy(){
         clearInterval(this.timer)
    }

}
</script>

<style lang='scss' scoped>
.box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.text{
    margin-top: 20px;
    font-size: 15px;
}
</style>